import React from 'react'
import Wrapper from '../components/wrapper'
import EmailPreference from '../components/pageComponents/manageEmailPreference'
export default function ManageEmailPreference({ location }) {
  return (
    <Wrapper location={location} title="Manage Email Preferences | MedReps"
      description="Easily update your preferences, unsubscribe from certain email categories or opt-out completely. We want to ensure your experience with our emails is personalized and valuable to you.">
      <EmailPreference />
    </Wrapper>
  )
}
