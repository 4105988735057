import React from 'react'
import HubspotForm from 'react-hubspot-form'
import { HUBSPOT_INTEGRATION } from '../../../data'

const ManageEmailPreferenceComponent = () => {
  return (
    <>
      <div className="container mx-auto min-h-700px py-12">
        <div className="px-6 sm:px-0 w-full sm:w-1/2 mx-auto">
          <h1 className="font-playfair text-2xl font-semibold pb-12">
            Manage Email Preference
          </h1>
          <HubspotForm
            portalId={HUBSPOT_INTEGRATION.PORTAL_ID}
            formId={HUBSPOT_INTEGRATION.EMAIL_PREFERENCE_FORM_ID}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
    </>
  )
}
export default ManageEmailPreferenceComponent
